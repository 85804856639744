import { render, staticRenderFns } from "./Vehicle_car.vue?vue&type=template&id=283439fb&scoped=true&"
import script from "./Vehicle_car.vue?vue&type=script&lang=js&"
export * from "./Vehicle_car.vue?vue&type=script&lang=js&"
import style0 from "./Vehicle_car.vue?vue&type=style&index=0&id=283439fb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "283439fb",
  null
  
)

export default component.exports